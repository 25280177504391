<template>
  <layout :title="$route.name" :options="options">
    <v-flex xs12 class="pa-3">
      <v-simple-table v-if="$vuetify.breakpoint.lgAndUp" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left fonte">
                NOME
              </th>
              <th class="text-left fonte">
                EMAIL
              </th>
              <th class="text-left fonte">
                CPF
              </th>
              <th class="text-left fonte">
                TELEFONE
              </th>
              <th class="text-left fonte">
                LOGIN
              </th>
              <th class="text-left fonte">
                ENTROU EM
              </th>
              <th class="text-left fonte">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in getUsers"
              :key="item._id"
              :style="`background: ${index % 2 === 0 ? '#f1f1f1' : ''}`"
            >
              <td @click="$router.push(`/view-user/${item._id}`)" class="font-weight-bold">{{ item.name }}</td>
              <td @click="$router.push(`/view-user/${item._id}`)" class="font-weight-bold">{{ item.email }}</td>
              <td @click="$router.push(`/view-user/${item._id}`)" class="font-weight-bold">{{ item.cpf }}</td>
              <td @click="$router.push(`/view-user/${item._id}`)" class="font-weight-bold">{{ item.phone }}</td>
              <td @click="$router.push(`/view-user/${item._id}`)" class="font-weight-bold">{{ item.nickname }}</td>
              <td @click="$router.push(`/view-user/${item._id}`)" class="font-weight-bold">{{ $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
              <td class="font-weight-bold">
                <v-btn icon @click="createConfirmAction({
                  icon: 'mdi-delete',
                  message: 'Deseja remover este usuário?',
                  action: 'deleteUser',
                  action_value: item
                })">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-list dark style="border-radius: 6px;" class="pa-0 ma-0" dense two-line v-else>
        <template v-for="(item, index) in getUsers">
          <v-list-item :key="item._id">
            <v-avatar>
              <v-icon> mdi-account-circle </v-icon>
            </v-avatar>
            <v-list-item-content @click="$router.push(`/view-user/${item._id}`)">
              <v-list-item-title> {{ item.name }} </v-list-item-title>
              <v-list-item-subtitle> Email: {{ item.email }} </v-list-item-subtitle>
              <v-list-item-subtitle> CPF: {{ item.cpf }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="createConfirmAction({
                  icon: 'mdi-delete',
                  message: 'Deseja remover este usuário?',
                  action: 'deleteUser',
                  action_value: item
                })">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      options: [  
      ]
    }
  },
  computed: {
    ...mapGetters(["getUsers"])
  },
  methods: {
    ...mapActions(["listUsers", "createConfirmAction"])
  },
  created() {
    this.listUsers();
  }
};
</script>
